<template>
  <li
    class="list-group-item"
    :class="{ open: isOpen, 'no-play': !accionClick }"
  >
    <a
      v-if="accionClick == 'pause'"
      href="#"
      title="Pausar"
      class="chapter-play"
      @click.prevent="onClick('pause')"
    >
      <i class="zmdi zmdi-pause-circle"></i>
    </a>
    <a
      v-if="accionClick == 'play'"
      href="#"
      title="Reproducir"
      class="chapter-play"
      @click.prevent="onClick('play')"
    >
      <i class="zmdi zmdi-play-circle"></i>
    </a>

    <a
      href="#"
      :title="title"
      class="chapter-trigger"
      v-on:click.prevent.capture="openDetail()"
    >
      <span class="counter">{{ data.n_episodio }}</span>
      <span class="content-title">{{ title }}</span>
      <div class="list-indicators float-right">
        <div
          class="progress"
          v-if="
            typeof data.seekTime !== 'undefined' &&
            typeof data.duration !== 'undefined'
          "
        >
          <div
            class="progress-bar"
            role="progressbar"
            :style="
              'width:' +
              convertToPercent(data.seekTime * 60, data.duration * 60) +
              '%'
            "
          ></div>
        </div>
        <span class="emitted" v-if="fechaRelativa" :title="fechaHora">{{
          fechaRelativa
        }}</span>
        <span class="duration" v-if="typeof data.duration !== 'undefined'"
          >{{ data.duration }} min</span
        >
        <span
          class="certificate-code"
          v-if="data.certificate && data.certificateCode"
          :title="'Calificación: ' + data.certificate"
          >{{ data.certificateCode }}</span
        >
        <span class="year" v-if="data.year">{{ data.year }}</span>
        <span class="user-rating" v-if="typeof data.rating !== 'undefined'"
          ><i class="zmdi zmdi-star"></i> {{ data.rating }}</span
        >
      </div>
    </a>

    <div class="list-detail">
      <div class="list-detail--wrapper">
        <a v-if="!accionClick" class="play-chapter">
          <figure>
            <ImageLazy :src="imgSrc" :err="posterLoadError()" :alt="title" />
          </figure>
        </a>

        <a
          v-if="accionClick == 'pause'"
          @click.prevent="onClick('pause')"
          title="Pausar"
          class="play-chapter"
        >
          <figure>
            <ImageLazy :src="imgSrc" :err="posterLoadError()" :alt="title" />
          </figure>
          <span><i class="zmdi zmdi-pause-circle-outline"></i></span>
        </a>

        <a
          v-if="accionClick == 'play'"
          @click.prevent="onClick('play')"
          title="Reproducir"
          class="play-chapter"
        >
          <figure>
            <ImageLazy :src="imgSrc" :err="posterLoadError()" :alt="title" />
          </figure>
          <span><i class="zmdi zmdi-play-circle-outline"></i></span>
        </a>

        <p>{{ synopsis }}</p>
      </div>

      <div class="list-actions">
        <a
          v-if="$listeners.favorite"
          href="#"
          class="btn btn-secondary btn-favorite"
          :class="{ favorited: data.favorite }"
          v-on:click.prevent="$emit('favorite')"
          :title="
            data.favorite
              ? 'Quitar de mis favoritos'
              : 'Agregar a mis favoritos'
          "
        >
          <i
            class="zmdi zmdi-favorite-outline"
            :class="{ 'zmdi-favorite': data.favorite }"
          ></i>
          <span class="sr-only">Favorito</span>
        </a>

        <form v-if="$listeners.score" role="form" novalidate>
          <fieldset class="rating-interaction">
            <template v-for="r in rating">
              <input
                type="radio"
                :id="'star' + r.score + '-' + data.idAsset"
                name="rating"
                :value="r.score"
                :checked="data.userRating == r.score"
              />
              <label
                v-on:click="$emit('score', r.score)"
                :for="'star' + r.score + '-' + data.idAsset"
                :title="r.desc"
              >
                <span>{{ r.desc }}</span>
              </label>
            </template>
          </fieldset>
        </form>
      </div>
    </div>
  </li>
</template>
<script>
const IMG_DEFAULT_ALPHA =
  "a5434fa604af965aa91eb3e32c523ae2f383bfdf6f6cbaa5682ed36a60b5b33e";

export default {
  name: "ChapterDetail",

  components: {
    ImageLazy: () => import("@/components/Image-lazy.vue"),
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      rating: tplay.rating(),
      blockByParentalControl: false,
    };
  },

  mounted() {
    // Control del control parental
    this.blockByParentalControl =
      telecentro.tplay.core.biz.parentalControl.blockAsset(this.data);
  },

  computed: {
    imgSrc() {
      const urlFromAlpha = this.data.poster.split("/").pop();

      if (this.blockByParentalControl || urlFromAlpha === IMG_DEFAULT_ALPHA) {
        return "../assets/images/no-poster-5-7.png";
      } else {
        return this.data.imgPreview || this.data.poster;
      }
    },
    fechaRelativa() {
      if (!this.data.startHourUnixTime) return null;

      var s = telecentro.tplay.core.utiles.fechaRelativa(
        this.data.startHourUnixTime
      );
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    fechaHora() {
      if (!this.data.startHourUnixTime) return null;

      var hm = telecentro.tplay.core.utiles.obtenerHHMMdesdeSegundos(
        this.data.startHourUnixTime
      );
      var dia = telecentro.tplay.core.utiles.obtenerContextoFechaDesdeSegundos(
        this.data.startHourUnixTime
      );

      return dia + " a las " + hm;
    },
    accionClick() {
      if (this.isPlaying && this.$listeners.pause) return "pause";
      if (!this.isPlaying && this.$listeners.play) return "play";

      return null;
    },

    title() {
      if (this.blockByParentalControl) {
        return "Capítulo bloqueado";
      } else {
        //console.log('el title', this.data.title.length);
        return this.data.title;
      }
    },

    synopsis() {
      if (this.blockByParentalControl) {
        return "Sipnosis bloqueada";
      } else {
        return this.data.synopsis;
      }
    },
  },

  methods: {
    posterLoadError() {
      return "../assets/images/no-poster-5-7.png";
    },

    convertToPercent(seekTime, duration) {
      if (seekTime > duration) {
        seekTime /= 60;
      }
      return seekTime ? (seekTime * 100) / duration : 0;
    },

    openDetail() {
      let self = this;

      if (this.blockByParentalControl) {
        this.$bus.$emit("modal-pin:show", function () {
          self.blockByParentalControl = false;
          _openDetail();
        });
      } else {
        _openDetail();
      }

      function _openDetail() {
        self.isOpen = !self.isOpen;

        if (self.isOpen) {
          self.$nextTick(function () {
            self.$el.scrollIntoView({ behavior: "smooth" });
          });
        }
      }
    },

    onClick(state) {
      let self = this;

      if (this.blockByParentalControl) {
        this.$bus.$emit("modal-pin:show", function () {
          self.$emit(state);
        });
      } else {
        this.$emit(state);
      }
    },
  },
};
</script>
